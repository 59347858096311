<template>
  <div :class="propsClassName">
    <div class="compositiondropdown_area">
      <div class="compositiondropdown_box d-flex align-items-center justify-content-between">
        <h4>{{$t("sabre.composition.adults")}}</h4>
        <h6>{{$t("sabre.composition.age")}} 18-65</h6>
        <spin-plus-minus-button  v-model="adults"/>
      </div>
      <div class="compositiondropdown_box d-flex align-items-center justify-content-between">
        <h4 style="margin-left: 1.5rem;">{{$t("sabre.composition.children")}}</h4>
        <h6>{{$t("sabre.composition.age")}} 2-18</h6>
        <spin-plus-minus-button  v-model="children"/>
      </div>
      <div class="compositiondropdown_box d-flex align-items-center justify-content-between">
        <h4 style="margin-left: 0.7rem;">{{$t("sabre.composition.infants")}}</h4>
        <h6>{{$t("sabre.composition.age")}} 0-2</h6>
        <spin-plus-minus-button  v-model="babies"/>
      </div>
      <div class="compositiondropdown_box d-flex align-items-center justify-content-between">
        <h4>{{$t("sabre.composition.seniors")}}</h4>
        <h6>65 {{$t("sabre.composition.more")}}</h6>
        <spin-plus-minus-button  v-model="seniors"/>
      </div>
      <div class="btn-group-area d-flex justify-content-center" v-if="type==='dropdown'">
        <button class="cleanup_btn" @click="clean">{{$t("sabre.buttons.clean")}}</button>
        <button class="save_btn" @click="close">{{$t("sabre.buttons.save")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import spinPlusMinusButton from '@/sabre/common/atom/spinPlusMinusButton';

export default {
  components: { spinPlusMinusButton },
  name: 'select-passenger',
  props: {
    propsClassName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      storedAdults: 'GET_SABRE_ADULTS_FOR_SEARCH',
      storedChildren: 'GET_SABRE_CHILDREN_FOR_SEARCH',
      storedBabies: 'GET_SABRE_BABIES_FOR_SEARCH',
      storedSeniors: 'GET_SABRE_SENIORS_FOR_SEARCH',
    }),
  },
  watch: {
    adults() {
      this.$store.commit('SET_SABRE_ADULTS_FOR_SEARCH', +this.adults);
    },
    children() {
      this.$store.commit('SET_SABRE_CHILDREN_FOR_SEARCH', +this.children);
    },
    babies() {
      this.$store.commit('SET_SABRE_BABIES_FOR_SEARCH', +this.babies);
    },
    seniors() {
      this.$store.commit('SET_SABRE_SENIORS_FOR_SEARCH', +this.seniors);
    },
  },
  data() {
    return {
      adults: 2,
      children: 0,
      babies: 0,
      seniors: 0,
    };
  },
  mounted() {
    if (this.adults) { this.$store.commit('SET_SABRE_ADULTS_FOR_SEARCH', +this.adults); }
    this.adults = +this.storedAdults;
    this.children = +this.storedChildren;
    this.babies = +this.storedBabies;
    this.seniors = +this.storedSeniors;
  },
  methods: {
    clean() {
      this.adults = 2;
      this.children = 0;
      this.babies = 0;
      this.seniors = 0;
    },
    close() {
      this.$emit('close');
    },
  },
};

</script>
